import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('scrollable',{scopedSlots:_vm._u([{key:"sticky",fn:function(){return [_c(VCard,{attrs:{"flat":""}},[_c(VCardTitle,{staticClass:"d-flex justify-space-between"},[_c('span',[_vm._v(_vm._s(_vm.$t('Boxes')))]),(_vm.$canAny('manage,cart\\box', 'create,cart\\box'))?_c(VBtn,{attrs:{"depressed":"","color":"primary","to":{ name: 'box-create' }}},[_vm._v(" "+_vm._s(_vm.$t('Add new box'))+" ")]):_vm._e()],1),_c(VDivider)],1)]},proxy:true},{key:"scrollable-content",fn:function(ref){
var heightExceeded = ref.heightExceeded;
return [_c('div',{staticClass:"px-4"},[_c('resource-grid',{attrs:{"selectable-key":"id","options":_vm.options,"items":_vm.grid.data,"hide-default-footer":""},on:{"onParamsChange":_vm.reloadCollection},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c(_vm.canAccessBox ? 'router-link' : 'span',{tag:"router-link",attrs:{"to":{ name: 'box-update', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.dimensions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column flex-gap-8 my-4"},[_c('div',{staticClass:"d-flex justify-space-between flex-wrap flex-gap-8"},[_c('span',[_vm._v(_vm._s(_vm.$t('Inner'))+":")]),_c('span',{staticClass:"d-flex flex-gap-8"},[_c('span',[_vm._v(_vm._s(item.inner_length)+" x "+_vm._s(item.inner_width)+" x "+_vm._s(item.inner_depth))]),_c('span',{staticClass:"grey--text"},[_vm._v(_vm._s(item.size_unit))])])]),_c(VDivider),_c('div',{staticClass:"d-flex justify-space-between flex-wrap flex-gap-8"},[_c('span',[_vm._v(_vm._s(_vm.$t('Outer'))+":")]),_c('span',{staticClass:"d-flex flex-gap-8"},[_c('span',[_vm._v(_vm._s(item.outer_length)+" x "+_vm._s(item.outer_width)+" x "+_vm._s(item.outer_depth))]),_c('span',{staticClass:"grey--text"},[_vm._v(_vm._s(item.size_unit))])])])],1)]}},{key:"item.empty_weight",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-flex flex-gap-8"},[_c('span',[_vm._v(_vm._s(item.empty_weight))]),_c('span',{staticClass:"grey--text"},[_vm._v(_vm._s(item.weight_unit))])])]}},{key:"item.max_weight",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-flex flex-gap-8"},[_c('span',[_vm._v(_vm._s(item.max_weight))]),_c('span',{staticClass:"grey--text"},[_vm._v(_vm._s(item.weight_unit))])])]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [(item.active)?_c(VIcon,[_vm._v("check")]):_c(VIcon,[_vm._v("close")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$format.timezone.getFormattedTime(item.created_at)))])]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$format.timezone.getFormattedTime(item.created_at)))])]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('button-action',{attrs:{"icon-name":"edit","icon":"","label":_vm.$t('Edit'),"disabled":_vm.grid.loading,"to":{ name: 'box-update', params: { id: item.id } }}})]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('box-action-delete',{attrs:{"icon":"","value":item},on:{"onSuccess":_vm.onDeleteSuccess}})]}}],null,true),model:{value:(_vm.params),callback:function ($$v) {_vm.params=$$v},expression:"params"}}),_c(VDivider),(!heightExceeded)?_c('div',{staticClass:"d-flex align-center pa-4 flex-gap-16 justify-end"},[_c('table-pagination',{attrs:{"meta":_vm.grid.meta},model:{value:(_vm.params),callback:function ($$v) {_vm.params=$$v},expression:"params"}})],1):_vm._e()],1)]}},{key:"sticky-bottom",fn:function(ref){
var heightExceeded = ref.heightExceeded;
return [_c(VDivider),(heightExceeded)?_c(VFooter,{staticClass:"d-flex align-center pa-4 flex-gap-16 justify-end"},[_c('table-pagination',{attrs:{"meta":_vm.grid.meta},model:{value:(_vm.params),callback:function ($$v) {_vm.params=$$v},expression:"params"}})],1):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }